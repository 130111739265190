import React, { useEffect, useState, useRef } from "react";
import Model from "../Model";
import styles from "./styles.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import ImageGallary from "../NFTDetalisPageGallary/index";
import Button from "../Button";
import { timeFormat } from "../../Hooks/timeFormat";
import ReactToPrint from "react-to-print";
const ShowOnIPFS = (props) => {
  const [details, setDetails] = useState({});
  const [showLoader, setLoader] = useState(false);
  const inputEl = useRef(null);
  useEffect(() => {
    try {
      if (props.visible == true) {
        setLoader(true);
        fetch(props.ipfsData.proof.ipfs_link)
          .then(async (response) => {
            let data = await response.json();
            return data;
          })
          .then((data) => {
            setLoader(false);
            var assets = Array.isArray(props.ipfsData.asset)
              ? props.ipfsData.asset.map((a, i) => {
                  return {
                    alt_text: a.alt_text,
                    file_type: a.file_type,
                    // src: { home_page_listings: `https://gateway.pinata.cloud/ipfs/${data.access_urls[i].split("/").pop()}` },
                    src: { home_page_listings: data.access_urls[i] },
                  };
                })
              : [
                  {
                    alt_text: props.ipfsData.asset.alt_text,
                    file_type: props.ipfsData.asset.file_type,
                    // src: { home_page_listings: `https://gateway.pinata.cloud/ipfs/${data.access_urls[0].split("/").pop()}` },
                    src: { home_page_listings: data.access_urls[0] },
                  },
                ];
            data.access_urls = assets;
            setDetails(data);
          });
      }
    } catch (err) {
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);
  return (
    <Model
      visible={props.visible}
      title={"IPFS Details"}
      className={`${styles.wallet_model}`}
      onHide={props.onHide}
      modelClass={styles.modelClass}
    >
      {showLoader ? (
        <div style={{ textAlign: "center" }}>
          <Loader
            type="ThreeDots"
            color="#7557BB"
            height={50}
            width={50}
            timeout={3000000} //3 secs
          />
        </div>
      ) : (
        <>
          <div className={`${styles.IpfsModelBody}`}>
            <p>
              <b>Title: </b>{" "}
              {props?.ipfsData?.title ? props?.ipfsData?.title : ""}
            </p>
            <p>
              <b>Creator Address: </b>{" "}
              {details?.creator_address ? details?.creator_address : ""}
            </p>
            {/* <p>
              <b>Is Collection: </b> {details?.is_collection ? "Yes" : "No"}
            </p> */}
            <p>
              <b>Created Date: </b>{" "}
              {details?.created_at
                ? new Date(details?.created_at).toDateString()
                : ""}
            </p>
            <p>
              <b>Category: </b> {props?.ipfsData?.metadata?.category}
            </p>
            <b>Asset: </b>
            {details.access_urls ? (
              <ImageGallary
                item={details.access_urls}
                currentObj={props?.ipfsData ? props?.ipfsData : {}}
              />
            ) : null}
          </div>
          <ReactToPrint
            trigger={() => (
              <div style={{ textAlign: "center" }}>
                <Button
                  varient={"secondary_outline"}
                  className={`${styles.btn__clr} m-3`}
                >
                  {"Print"}
                </Button>
              </div>
            )}
            content={() => inputEl.current}
          />

          <div style={{ display: "none" }}>
          {/* <div> */}
            <div className={`${styles.IpfsModelBody} m-5`} ref={inputEl}>
              <div style={{ textAlign: "center" }}>
                <img
                  src="https://d2okfe1b01fuq1.cloudfront.net/profile/profile-419896416177524929.png"
                  alt="NFTON logo"
                />
              </div>
              <p>
                <b>Title: </b>{" "}
                {props?.ipfsData?.title ? props?.ipfsData?.title : ""}
              </p>
              <p>
                <b>Creator Address: </b>{" "}
                {details?.creator_address ? details?.creator_address : ""}
              </p>
              {/* <p>
              <b>Is Collection: </b> {details?.is_collection ? "Yes" : "No"}
            </p> */}
              <p>
                <b>Created Date: </b>{" "}
                {details?.created_at
                  ? new Date(details?.created_at).toDateString()
                  : ""}
              </p>
              <p>
                <b>Category: </b> {props?.ipfsData?.metadata?.category}
              </p>
              {details.access_urls &&
              props?.ipfsData?.metadata?.category !== "Music" &&
              props?.ipfsData?.metadata?.category !== "Video" ? (
                <>
                  <b>Asset: </b>
                  {/* <ImageGallary
                    item={details.access_urls}
                    currentObj={props?.ipfsData ? props?.ipfsData : {}}
                    showSlider={"no"}
                  /></> */}
                  <div className={`${styles.imagecontainer}`}>
                    {details.access_urls.map((img, index) => {
                      return (
                        <img
                          key={index}
                          style={{ margin: "20px" }}
                          src={
                            img?.src?.home_page_listings ??
                            "/assets/img/image-loader.gif"
                          }
                          alt={img?.alt_text}
                          onError={(e) => {
                            e.target.onerror = null;
                            let arr = (
                              img?.src?.home_page_listings ??
                              "/assets/img/image-loader.gif"
                            )?.split(".");
                            arr.pop();
                            if (img.file_type.includes("gif")) {
                              e.target.src = arr.join(".") + ".gif";
                            } else {
                              e.target.src = arr.join(".") + ".jpg";
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </Model>
  );
};

export default ShowOnIPFS;
